import { gql, useMutation } from "@apollo/client";

const useEmailConfirmation = () => {
  const MUTATE = gql`
    mutation EmailConfirmation($confirmation: String!) {
      emailConfirmation(confirmation: $confirmation) {
        jwt
        user {
          email
          confirmed
          id
        }
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return [data, loading, error, setData];
};

export default useEmailConfirmation;
