import { gql } from "@apollo/client";
import client from "../utils/apolloClient";

const fetchLocationHours = async (id) => {
  const QUERY = gql`
    fragment availableHours on ComponentLocationAvailableHours {
      days {
        openAt
        closeAt
        day
      }
      enable
    }

    query GetLocation($id: ID!) {
      storefrontLocation(id: $id) {
        businessHours {
          ...availableHours
        }

        closedHours {
          ...availableHours
        }

        delivery {
          ...availableHours
        }
        pickup {
          ...availableHours
        }
        externalDeliverylink
      }
    }
  `;

  return client.query({
    query: QUERY,
    variables: { id },
  });
};

export default fetchLocationHours;
