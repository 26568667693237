import React, { useEffect, useState } from "react";

const MaintenanceContext = React.createContext({});

const MaintenanceContextProvider = ({ children }) => {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

  useEffect(() => {
    const parseValue = JSON.parse(process.env.GATSBY_IS_UNDER_MAINTENANCE);
    setIsUnderMaintenance(parseValue || false);
  }, []);

  return (
    <MaintenanceContext.Provider
      value={{
        isUnderMaintenance,
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};
export default MaintenanceContext;
export { MaintenanceContextProvider, MaintenanceContext };
