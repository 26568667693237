import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import fetch from "isomorphic-fetch";
import { setContext } from "@apollo/client/link/context";
import { getUserInfo } from "../context/userAuth";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = getUserInfo();

  // create a variable to hold the authorization
  let authorization = "";
  if (user && user.accessToken) {
    authorization = `Bearer ${user.accessToken}`;
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: authorization,
    },
  };
});

const link = new HttpLink({
  uri: `${process.env.GATSBY_STRAPI_GRAPHQL_URL}`,
  fetch,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

export default client;
