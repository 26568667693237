import _ from "lodash";

const postalCodePatternByCountry = {
  canada: {
    pattern: "[A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]",
    formatExample: "H7V 2V3",
  },
  denmark: {
    pattern: "[0-9]{4}",
    formatExample: "2400",
  },
};

const postalCodeChecker = (postalCode, country) => {
  if (_.isEmpty(country)) return false;
  const key = _.lowerCase(country);

  return postalCode.match(postalCodePatternByCountry[key].pattern);
};

export { postalCodePatternByCountry };
export default postalCodeChecker;
