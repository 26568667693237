import { gql, useMutation } from "@apollo/client";
import Fragments from "../fragments";

const useApplyOffer = () => {
  const MUTATE = gql`
    mutation ApplyOffer($cartToken: String!, $code: String!) {
      applyOffer(cartToken: $cartToken, code: $code) {
        cart {
          ...CartFragment
        }
      }
    }
    ${Fragments.cart}
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useApplyOffer;
