import { useIntl } from "react-intl";

const useTrans = () => {
  const intl = useIntl();

  const transMsg = (id, variables = {}) => {
    return intl.formatMessage({ id }, variables);
  };
  return transMsg;
};

export default useTrans;
