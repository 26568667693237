import { gql } from "@apollo/client";

const offer = gql`
  fragment OfferFragment on Offer {
    id
    code
    type
  }
`;

export default offer;
