import React, { useState } from "react";

const SearchContext = React.createContext({});

const SearchContextProvider = ({ children }) => {
  const [searchResult, setSearchResult] = useState([]);

  return (
    <SearchContext.Provider
      value={{
        searchResult,
        setSearchResult,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
export default SearchContext;
export { SearchContextProvider, SearchContext };
