import { useEffect, useContext, useState } from "react";
import _ from "lodash";
import { CartContext } from "../context";

import { useCart } from "../queries";

const useUpdateCartContext = () => {
  const { data: dataCart, onExecuteQuery: executeCart } = useCart();
  const {
    cartToken,
    setCartLineItems,
    setCartTotals,
    setOrderType,
    setCartEmail,
    setShippingAddress,
    setBillingAddress,
    setScheduleTime,
    setCartPhone,
    setCanDeliver,
    setCartOffer,
  } = useContext(CartContext);

  const [updated, setUpdated] = useState(false);

  const onUpdate = (data) => {
    // check if data is not empty
    if (!_.isEmpty(data)) {
      updateCartContext(data);
      return;
    }

    if (_.isEmpty(cartToken)) return;
    // get the cart data from the server
    setUpdated(false);
    executeCart({ token: cartToken });
  };

  useEffect(() => {
    if (_.isEmpty(dataCart)) return;
    if (_.isEmpty(dataCart.storefrontCart)) return;
    if (_.isEmpty(dataCart.storefrontCart.lineitems)) return;
    updateCartContext(dataCart.storefrontCart);
    setUpdated(true);
  }, [dataCart]);

  const updateCartContext = (data) => {
    if (_.isEmpty(data)) return;

    let totalQty = 0;

    if (!_.isEmpty(data.lineitems)) {
      let lineitems = {};
      data.lineitems.forEach((v) => {
        //   console.log(`item-${v.item.id}`, v);
        totalQty += v.quantity;
        lineitems[v.id] = {
          id: v.id,
          item: { id: v.item.id, price: v.item.price },
          quantity: v.quantity,
          choices: v.choices,
          note: v.note,
          localeShareId: v.item.localeShareId,
          image: v.item.photo ? v.item.photo.url : undefined,
          unitPrice: v.unitPrice,
          total: v.totalPrice,
          title: v.title,
        };
      });
      setCartLineItems(lineitems);
    }

    setCartTotals((c) => {
      return {
        ...c,
        qty: totalQty,
        total: data.total,
        subtotal: data.subtotal,
        taxTotal: data.taxTotal,
        discountTotal: data.discountTotal,
        deliveryFees: data.deliveryFees,
        tip: data.tip,
      };
    });

    if (data.shippingAddress) setShippingAddress(data.shippingAddress);

    if (data.billingAddress) setBillingAddress(data.billingAddress);

    if (data.orderType) setOrderType(data.orderType);
    if (data.scheduleTime) setScheduleTime(data.scheduleTime);

    if (data.email) setCartEmail(data.email);
    if (data.phone) setCartPhone(data.phone);
    if (!_.isUndefined(data.canDeliver)) setCanDeliver(data.canDeliver);
    if (!_.isUndefined(data.offer)) setCartOffer(data.offer);
  };

  return { updated, onUpdate };
};

export default useUpdateCartContext;
