import React, { createContext } from "react";

import { useJsApiLoader } from "@react-google-maps/api";

const GoogleMapsContext = createContext({});

const GoogleMapsContextPorvider = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  return (
    <GoogleMapsContext.Provider
      value={{
        isLoaded,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};
export default GoogleMapsContext;
export { GoogleMapsContextPorvider, GoogleMapsContext };
