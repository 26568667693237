import { gql } from "@apollo/client";
import address from "./address";
import location from "./location";
import offer from "./offer";

const order = gql`
  fragment OrderFragment on Orders {
    token
    tip
    total
    taxTotal
    subtotal
    discountTotal
    deliveryFees
    displayOrderId
    type
    email
    phone
    created_at
    state
    approvedAt
    readyAt
    completedAt
    canceledAt
    cancelReason
    preparationTime
    note
    scheduleTime {
      display
      from
      to
    }
    lineitems {
      title
      note
      item {
        id
        price
        photo {
          url
          alternativeText
        }
        name
      }
      choices {
        title
        item {
          id
          name
          price
        }
        modifier {
          id
          name
        }
        unitPrice
        quantity
      }
      quantity
      totalPrice
    }
    offer {
      ...OfferFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    billingAddress {
      ...AddressFragment
    }
    location {
      ...LocationFragment
    }
    deliver {
      customerTrackURL
    }
  }
  ${location}
  ${address}
  ${offer}
`;

export default order;
