import { gql, useMutation } from "@apollo/client";

const useConfirmPayament = () => {
  const MUTATE = gql`
    mutation ConfirmPayament(
      $restaurant: ID!
      $cartToken: String!
      $stripeAccountId: String!
      $amount: Float!
      $location: ID!
      $id: String
    ) {
      confirmPayament(
        input: {
          restaurant: $restaurant
          cartToken: $cartToken
          stripeAccountId: $stripeAccountId
          amount: $amount
          location: $location
          id: $id
        }
      ) {
        id
        clientSecret
        currency
        amount
        orderToken
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useConfirmPayament;
