import { useEffect, useState } from "react";
import _ from "lodash";
import queryString from "query-string";
import { navigate } from "gatsby";

const useRemoveSearchParams = (location) => {
  const [params, setParams] = useState({});

  useEffect(() => {
    if (_.isEmpty(location)) return;
    if (location.search) {
      const _params = queryString.parse(location.search);
      if (_.isEmpty(_params)) return;

      navigate(location.pathname, { state: _params, replace: true });
    } else if (location.state) {
      setParams(location.state);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return params;
};

export default useRemoveSearchParams;
