import { gql } from "@apollo/client";
import address from "./address";

const location = gql`
  fragment LocationFragment on Locations {
    name
    address {
      ...AddressFragment
    }
  }
  ${address}
`;
export default location;
