// NOT USED YET
import { useContext, useMemo } from "react";
import { CartContext, LocationsContext } from "../context";
import _ from "lodash";
import usePostalCodePattern from "./usePostalCodePattern";

const useUpdateLocationBasedOnPostalCode = () => {
  // get the locations from the context
  const { locations, selectedLocation, setSelectedLocation } = useContext(LocationsContext);
  // get the shipping address from the cart context
  const { shippingAddress } = useContext(CartContext);

  // get the postal code pattern for the selected location
  const postalCodePattern = usePostalCodePattern({
    country: selectedLocation?.country,
  });

  // get the delivery fees for the selected location
  const deliveryFees = useMemo(() => {
    if (_.isEmpty(locations)) return {};
    if (!_.isArray(locations)) return {};

    const deliveryOptionsJson = locations.reduce((r, l) => {
      r = { ...r, ...l.deliveryOptionsJson };
      return r;
    }, {});
    if (_.isEmpty(deliveryOptionsJson)) return {};

    return deliveryOptionsJson;
  }, [locations]);

  const postalCodeInfo = () => {
    // if the delivery fees are empty, return
    // if the shipping address is empty, return
    // if the postal code pattern is empty, return
    // we need all of these to get the postal code info
    if (
      _.isEmpty(deliveryFees) ||
      _.isEmpty(shippingAddress) ||
      _.isEmpty(postalCodePattern?.pattern)
    )
      return;

    // if the postal code is empty, return
    if (_.isEmpty(shippingAddress?.postalCode)) return;

    // test the postal code against the pattern to make sure it is valid
    const testPattern = new RegExp(postalCodePattern.pattern).test(
      shippingAddress.postalCode,
    );
    if (!testPattern) return;

    // get the postal code info
    const checkByCountry = checkPostalCode[_.lowerCase(shippingAddress.country)];

    // if the function to get the postal code info for delivery fees is not found, return
    // it means that we don't have the delivery fees for the country
    if (!_.isFunction(checkByCountry)) return;

    // return the postal code info for delivery fees
    return checkByCountry(deliveryFees, shippingAddress.postalCode);
  };

  const updateSelectedLocation = (info) => {
    // check if there are more than one location for the restaurant
    if (locations?.length < 2) return;
    // create a variable to hold the postal code info if it is passed as a parameter
    let _info = info;
    // if the postal code info is not passed as a parameter, get it from the function
    if (!_info) _info = postalCodeInfo();

    // if the postal code info is not valid, return
    if (_.isEmpty(_info) || !_.isObject(_info)) return;

    // if the selected location is the same as the one that is passed as a parameter, return
    // no need to update the selected location
    if (_info.id === _.toInteger(selectedLocation.id)) return;

    // find the location that matches the id that is passed as a parameter
    const res = locations.find((l) => _.toInteger(l.id) === _info.id);
    // if the location is not found, return
    if (!res) return;

    // update the selected location
    setSelectedLocation(res);
  };

  return updateSelectedLocation;
};

const checkPostalCode = {
  canada: (deliveryFees, postalCode) => {
    // remove all the spaces from the postal code and convert it to uppercase
    const pc = _.trim(postalCode.toUpperCase());
    // get the first three digits of the postal code
    const threeDigite = pc.slice(0, 3);
    // format the postal code with the first three digits and the last three digits
    // capitalized and separated by a space
    const formattedPC = `${threeDigite} ${pc.slice(-3)}`;
    // check if the delivery fees has the postal code with the first three digits and the last three digits
    const feeWithFull = deliveryFees[formattedPC];
    // if it does, return the fee
    if (feeWithFull) return feeWithFull;

    // check if the delivery fees has the postal code with the first three digits
    const feeWithThreeDigite = deliveryFees[threeDigite];
    // if it does, return the fee
    if (feeWithThreeDigite) return feeWithThreeDigite;

    // if the delivery fees does not have the postal code, return the fee for other
    return deliveryFees.other;
  },
  denmark: (deliveryFees, postalCode) => {
    const pc = _.trim(postalCode);
    const towDigite = pc.slice(0, 2);
    const formattedPC = `${towDigite}${pc.slice(-2)}`;
    const feeWithFull = deliveryFees[formattedPC];
    if (feeWithFull) return feeWithFull;

    const feeWithTowDigite = deliveryFees[towDigite];
    if (feeWithTowDigite) return feeWithTowDigite;

    return deliveryFees.other;
  },
};
export default useUpdateLocationBasedOnPostalCode;
