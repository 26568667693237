import { gql, useMutation } from "@apollo/client";

const useSendEmailConfirmation = () => {
  const MUTATE = gql`
    mutation SendEmailConfirmation($email: String!, $restaurant: ID!) {
      sendEmailConfirmation(input: { email: $email, restaurant: $restaurant }) {
        email
        sent
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useSendEmailConfirmation;
