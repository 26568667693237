import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import Fragments from "../fragments";

const useCart = () => {
  const QUERY = gql`
    query GetCart($token: String!) {
      storefrontCart(token: $token) {
        ...CartFragment
      }
    }
    ${Fragments.cart}
  `;
  const [executeQuery, { data, loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
  });

  const onExecuteQuery = (variables) => {
    executeQuery({ variables });
  };

  return { data, loading, error, onExecuteQuery };
};

export default useCart;
