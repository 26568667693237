import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import Fragments from "../fragments";

const useOrder = () => {
  const QUERY = gql`
    query GetOrder($token: String!) {
      storefrontOrder(token: $token) {
        ...OrderFragment
      }
    }

    ${Fragments.order}
  `;
  const [executeQuery, { data, loading, error, startPolling, stopPolling }] =
    useLazyQuery(QUERY);

  const onExecuteQuery = (variables) => {
    executeQuery({ variables });
  };

  return { data, loading, error, onExecuteQuery, startPolling, stopPolling };
};

export default useOrder;
