import { gql, useMutation } from "@apollo/client";

const useForgotPassword = () => {
  const MUTATE = gql`
    mutation ForgotPassword($email: String!) {
      forgotPassword(email: $email) {
        ok
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useForgotPassword;
