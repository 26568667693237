import { gql } from "@apollo/client";

const address = gql`
  fragment AddressFragment on ComponentLocationAddress {
    firstName
    lastName
    phone
    address_1
    address_2
    postalCode
    country
    countryCode
    city
    province
    description
    lat
    long
  }
`;

export default address;
