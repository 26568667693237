import * as React from "react";
import { LocaleContext } from "gatsby-theme-i18n";
import { graphql, useStaticQuery } from "gatsby";

const useLocalization = () => {
  const locale = React.useContext(LocaleContext);
  const {
    themeI18N: { defaultLang, prefixDefault, config, locales },
  } = useStaticQuery(graphql`
    query LocalizationQuery {
      themeI18N {
        defaultLang
        prefixDefault
        config {
          code
          hrefLang
          dateFormat
          langDir
          localName
          name
        }
        locales
      }
    }
  `);

  return {
    locale,
    defaultLang,
    prefixDefault,
    config,
    locales,
  };
};

export default useLocalization;
