import { useContext, useMemo } from "react";
import { LocationsContext } from "../context";
import moment from "moment";
import _ from "lodash";

const useBusinessIsOpen = ({ hoursType = "businessHours" } = {}) => {
  const { selectedLocation } = useContext(LocationsContext);

  const getLoationDate = (time, location) => {
    if (_.isEmpty(location)) return;
    if (_.isEmpty(location[hoursType]?.days)) return;
    const day = _.lowerCase(time.format("dddd"));
    const dayHours = _.find(location[hoursType].days, (d) => d.day === day);
    // this is the random closed hours that are set by the owner of the restaurant
    const randomazoClosedHours = _.find(location?.closedHours?.days, (d) => d.day === day);

    if (_.isEmpty(dayHours) || _.isEmpty(dayHours.openAt) || _.isEmpty(dayHours.closeAt))
      return {
        day,
        time,
        dayHours: { day },
        openTime: undefined,
        closeTime: undefined,
      };

    const [oh, om, os] = _.split(dayHours.openAt, ":");
    const openTime = moment(time).hours(oh).minute(om).second(os);
    const [ch, cm, cs] = _.split(dayHours.closeAt, ":");
    let closeTime = moment(time).hours(ch).minute(cm).second(cs);
    closeTime = ch < oh ? closeTime.add(1, "days") : closeTime;

    if (_.isEmpty(randomazoClosedHours)) {
      return {
        day,
        time,
        dayHours,
        openTime,
        closeTime,
      };
    } else {
      // create a variable that will hold the random closed hours in moment format
      const [roh, rom, ros] = _.split(randomazoClosedHours?.openAt, ":");
      const randomazoOpenTime = moment(time).hours(roh).minute(rom).second(ros);
      const [rch2, rcm2, rcs2] = _.split(randomazoClosedHours?.closeAt, ":");
      let randomazoCloseTime = moment(time).hours(rch2).minute(rcm2).second(rcs2);
      randomazoCloseTime =
        rch2 < roh ? randomazoCloseTime.add(1, "days") : randomazoCloseTime;

      return {
        day,
        time,
        dayHours,
        openTime,
        closeTime,
        randomazoOpenTime,
        randomazoCloseTime,
      };
    }
  };

  const today = useMemo(() => {
    const time = moment();
    return getLoationDate(time, selectedLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, hoursType, selectedLocation?.closedHours]);

  const previousOpenDay = useMemo(() => {
    const getDay = (i) => {
      const time = moment().subtract(i, "d");
      const res = getLoationDate(time, selectedLocation);
      if (res && !res.closeTime) return getDay(i + 1);
      return res;
    };

    return getDay(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, hoursType, selectedLocation?.closedHours]);

  const nextOpenDay = useMemo(() => {
    const getDay = (i) => {
      const time = moment().add(i, "d");
      const res = getLoationDate(time, selectedLocation);
      if (res && !res.closeTime) return getDay(i + 1);
      return res;
    };

    return getDay(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, hoursType, selectedLocation?.closedHours]);

  // ========================================================
  // After this line is functions that are exposed
  const dateNow = useMemo(() => {
    if (_.isEmpty(today) || _.isEmpty(previousOpenDay) || _.isEmpty(nextOpenDay)) return;

    if (previousOpenDay.closeTime > today.time) {
      return previousOpenDay;
    }

    if (
      !today.closeTime ||
      today.closeTime < today.time ||
      (today.randomazoOpenTime < today.time && today.time < today.randomazoCloseTime)
    ) {
      return nextOpenDay;
    }

    return today;
  }, [today, previousOpenDay, nextOpenDay]);

  const nextDate = useMemo(() => {
    if (_.isEmpty(today) || _.isEmpty(nextOpenDay) || _.isEmpty(previousOpenDay)) return;

    if (previousOpenDay.closeTime > today.time) return today;

    return nextOpenDay;
  }, [today, nextOpenDay, previousOpenDay]);

  const isOpen = useMemo(() => {
    if (_.isEmpty(dateNow)) return false;
    if (_.isEmpty(selectedLocation)) return false;
    if (_.isEmpty(dateNow.dayHours)) return false;
    const now = moment();

    // if the random closed hours are set
    // and the current time is between the open and close time of the random closed hours
    if (dateNow.randomazoOpenTime < now && now < dateNow.randomazoCloseTime) {
      return false;
    }

    return now > dateNow.openTime && dateNow.time < dateNow.closeTime;
  }, [dateNow, selectedLocation]);

  const formatTime = (time) => {
    if (_.isEmpty(time)) return;
    const [h, m, s] = _.split(time, ":");
    return moment().hours(h).minute(m).second(s).format("h:mm a");
  };

  const isCloseNextDay = (openAt, closeAt) => {
    // eslint-disable-next-line no-unused-vars
    const [oh, om, os] = _.split(openAt, ":");
    // eslint-disable-next-line no-unused-vars
    const [ch, cm, cs] = _.split(closeAt, ":");
    return ch < oh;
  };

  // create a function that will take schedule time
  // and check if the schedule time is for now as in the current business hours
  // or if it is for later as in the next business hours
  const isScheduleNow = (scheduleTime) => {
    if (_.isEmpty(scheduleTime) || !scheduleTime) return;

    // schedule time is sometimes in the format of 2021-05-20T16:00:00.000Z
    // and sometimes in the format of moment object
    // so we need to check the type of the schedule time
    // and convert it to moment object if it is not already
    const scheduleTimeMoment = moment.isMoment(scheduleTime)
      ? scheduleTime
      : moment(scheduleTime);

    // if the schedule time is between the current business hours
    if (scheduleTimeMoment < today.closeTime && today.time > dateNow.openTime) {
      return true;
    }

    return false;
  };

  return {
    formatTime,
    isOpen,
    dateNow,
    nextDate,
    isCloseNextDay,
    isScheduleNow,
  };
};

export default useBusinessIsOpen;
