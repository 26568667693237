import { useEffect, useContext, useState } from "react";
import { CustomerContext, LocationsContext, CartContext } from "../context";
import _ from "lodash";
import { useCreateCart } from "../mutations";
import useUpdateCartContext from "./useUpdateCartContext";

const useCreateCartHoook = () => {
  // set created to false to force the cart to be created
  const [created, setCreated] = useState(false);

  // get the context of the customer, selected location, and cart
  const { customer } = useContext(CustomerContext);
  const { selectedLocation } = useContext(LocationsContext);
  const {
    cartToken,
    setCartId,
    setCartToken,
    cartLineItems,
    cartEmail,
    orderType,
    shippingAddress,
    scheduleTime,
    cartPhone,
    cartTip,
  } = useContext(CartContext);

  // use the useCreateCart graphql mutation
  const { data: dataCreateCart, setData: setCreateCartVariable, error } = useCreateCart();

  // use the useUpdateCartContext hook
  const { onUpdate: updateCartContext } = useUpdateCartContext();

  const onCreate = () => {
    setCreated(false);
    if (!_.isEmpty(cartToken)) return;
    if (_.isEmpty(selectedLocation)) return;
    let variables = {
      location: selectedLocation.id,
      restaurant: process.env.GATSBY_STRAPI_RESTAURANT_ID,
      state: "CREATED",
    };
    if (!_.isEmpty(customer)) {
      variables.user = customer.id;
      variables.email = customer.email;
    }

    if (!_.isEmpty(cartLineItems))
      variables.lineitems = _.map(cartLineItems, (v, k) => {
        let lineItem = {
          item: v.item.id,
          quantity: v.quantity,
          note: v.note,
          title: v.title,
        };
        if (!_.isEmpty(v.choices)) {
          lineItem.choices = v.choices.map((c) => {
            return {
              modifier: c.modifier.id,
              quantity: c.quantity,
              item: c.item.id,
            };
          });
        }
        return lineItem;
      });

    const addressKeys = [
      "address_1",
      "address_2",
      "city",
      "province",
      "postalCode",
      "country",
      "firstName",
      "lastName",
      "phone",
      "countryCode",
      "lat",
      "long",
    ];

    if (!_.isEmpty(shippingAddress)) {
      // if (!_.isEmpty(shippingAddress?.postalCode)) {
      variables.shippingAddress = _.pick(shippingAddress, addressKeys);

      console.log("shippingAddress", shippingAddress);
    }

    if (!_.isEmpty(orderType)) variables.orderType = orderType;

    if (!_.isEmpty(cartEmail)) variables.email = cartEmail;
    if (!_.isEmpty(cartPhone)) variables.phone = cartPhone;
    if (!_.isEmpty(scheduleTime)) variables.scheduleTime = scheduleTime;
    if (cartTip > 0) variables.tip = cartTip;
    console.log("variables", variables);
    setCreateCartVariable({
      variables,
    });
  };

  useEffect(() => {
    if (_.isEmpty(dataCreateCart?.createCart?.cart)) return;
    setCreated(true);

    setCartId(dataCreateCart.createCart.cart.id);
    setCartToken(dataCreateCart.createCart.cart.token);
    updateCartContext(dataCreateCart.createCart.cart);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreateCart]);

  useEffect(() => {
    // check if error is not empty
    if (!_.isEmpty(error)) {
      // if error is not empty, log the error
      console.log("error creating cart", error);
      return;
    }

    console.log("no error when creating cart", error);
  }, [error]);

  return { created, onCreate };
};

export default useCreateCartHoook;
