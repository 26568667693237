import { gql, useMutation } from "@apollo/client";
import Fragments from "../fragments";

const useUpdateCart = () => {
  const MUTATE = gql`
    mutation UpdateCart(
      $id: ID!
      $restaurant: ID!
      $location: ID!
      $token: String!
      $user: ID
      $shippingAddress: editComponentLocationAddressInput
      $billingAddress: editComponentLocationAddressInput
      $offer: ID
      $email: String
      $phone: String
      $state: ENUM_CARTS_STATE
      $lineitems: [editComponentOrderLineitemInput]
      $orderType: ENUM_CARTS_ORDERTYPE
      $note: String
      $tip: Float
      $scheduleTime: editComponentOrderScheduleTimeInput
    ) {
      updateCart(
        input: {
          where: { id: $id }
          data: {
            restaurant: $restaurant
            user: $user
            token: $token
            shippingAddress: $shippingAddress
            billingAddress: $billingAddress
            offer: $offer
            location: $location
            email: $email
            state: $state
            phone: $phone
            tip: $tip
            lineitems: $lineitems
            orderType: $orderType
            note: $note
            scheduleTime: $scheduleTime
          }
        }
      ) {
        cart {
          ...CartFragment
        }
      }
    }
    ${Fragments.cart}
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useUpdateCart;
