import React, { useState } from "react";
import { useSessionStorage } from "../hooks";
import { SocketContext } from "./socketContext";
import { useEffect } from "react";
import fetchLocationHours from "../queries/fetchLocationHours";
import moment from "moment";
import _ from "lodash";

const isLocationOpen = (location, time = moment(), hoursType = "businessHours") => {
  if (_.isEmpty(location) || _.isEmpty(location[hoursType]?.days)) return false;

  const day = _.lowerCase(time.format("dddd"));
  const dayHours = _.find(location[hoursType].days, (d) => d.day === day);

  if (_.isEmpty(dayHours) || _.isEmpty(dayHours.openAt) || _.isEmpty(dayHours.closeAt)) {
    return false;
  }

  const [oh, om] = _.split(dayHours.openAt, ":");
  const openTime = moment(time).set({
    hour: parseInt(oh),
    minute: parseInt(om),
    second: 0,
  });

  const [ch, cm] = _.split(dayHours.closeAt, ":");
  let closeTime = moment(time).set({ hour: parseInt(ch), minute: parseInt(cm), second: 0 });

  // Handle closing times past midnight
  if (closeTime.isBefore(openTime)) {
    closeTime.add(1, "day");
  }

  // Check if the current time is within business hours
  const isWithinBusinessHours = time.isBetween(openTime, closeTime, null, "[]");

  // Check for closed hours (if any)
  const closedHours = location.closedHours?.days;
  if (!_.isEmpty(closedHours)) {
    const closedPeriod = _.find(closedHours, (d) => d.day === day);
    if (closedPeriod) {
      const [coh, com] = _.split(closedPeriod.openAt, ":");
      const closedOpenTime = moment(time).set({
        hour: parseInt(coh),
        minute: parseInt(com),
        second: 0,
      });

      const [cch, ccm] = _.split(closedPeriod.closeAt, ":");
      let closedCloseTime = moment(time).set({
        hour: parseInt(cch),
        minute: parseInt(ccm),
        second: 0,
      });

      if (closedCloseTime.isBefore(closedOpenTime)) {
        closedCloseTime.add(1, "day");
      }

      if (time.isBetween(closedOpenTime, closedCloseTime, null, "[]")) {
        return false; // Location is closed during this period
      }
    }
  }

  return isWithinBusinessHours;
};

export type AddressType = {
  address_1: string;
  address_2: string;
  country: string;
  countryCode: string;
  city: string;
  description: string;
  firstName: string;
  lastName: string;
  lat: string;
  long: string;
  postalCode: string;
  province: string;
  phone: string;
};

export type ContactType = {
  firstname: string;
  lastname: string;
  jobTitle: string;
  email: string;
  phone: string;
};

export type AvailableHoursType = {
  day: string;
  openAt: string;
  closeAt: string;
};

export type BusinessHoursType = {
  days: AvailableHoursType[];
  enable: boolean;
};

export type DeliveryType = {
  days: AvailableHoursType[];
  enable: boolean;
};

export type PickupType = {
  days: AvailableHoursType[];
  enable: boolean;
};

export type LocationType = {
  id: string;
  address: AddressType;
  businessHours: BusinessHoursType;
  delivery: DeliveryType;
  pickup: PickupType;
  // deliveryOptionsJson: DeliveryOptionsJsonType;
  // externalDeliverylink: ExternalDeliverylinkType;
  deliveryOptionsJson: any;
  externalDeliverylink: any;
  name: string;
  currency: string;
  contact: ContactType;
  photo: any;
};

// define the locations context type
export type LocationsContextType = {
  locations: LocationType[];
  setLocations: React.Dispatch<React.SetStateAction<LocationType[]>>;
  selectedLocation: LocationType;
  setSelectedLocation: React.Dispatch<React.SetStateAction<LocationType>>;
  isOpenExternalDeliveryModal: boolean;
  setIsOpenExternalDeliveryModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenMultiLocationModal: boolean;
  setIsOpenMultiLocationModal: React.Dispatch<React.SetStateAction<boolean>>;
  getDistanceMatrixToLocations: (
    origin: { lat: number; lng: number },
    locations: LocationType[],
  ) => Promise<google.maps.DistanceMatrixResponse>;
  getClosestLocation: (
    googleMapsDistanceMatrixResponse: google.maps.DistanceMatrixResponse,
    locations: LocationType[],
  ) => LocationType;
  fetchLocationBusinessHours: (locationId: string) => Promise<any>;
  setSelectedLocationBusinessHours: ({
    businessHours,
    closedHours,
    delivery,
    pickup,
    enable,
  }) => void;
};

const LocationsContext = React.createContext<LocationsContextType>(
  {} as LocationsContextType,
);

const locationFunctions = {
  // get the distance between the origin and the restaurant locations
  getDistanceMatrixToLocations: (
    origin: { lat: number; lng: number },
    locations: LocationType[],
  ): Promise<google.maps.DistanceMatrixResponse> => {
    // convert the locations data to the format google maps accepts
    // [{lat: 1, lng: 2}, {lat: 3, lng: 4}]
    let googleMapsDestinations = locations.map((location) => ({
      lat: parseFloat(location.address.lat),
      lng: parseFloat(location.address.long),
    }));

    // initialize the google maps distance matrix service
    const service = new window.google.maps.DistanceMatrixService();

    // call the distance matrix service
    return new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: googleMapsDestinations,
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidTolls: true,
        },
        (response, status) => {
          // if the response is not ok, return and don't do anything
          if (status !== "OK" || response === null) {
            return reject();
          }

          resolve(response);
        },
      );
    });
  },
  // get the closest location from the google maps response and the locations data
  getClosestLocation: (
    googleMapsDistanceMatrixResponse: google.maps.DistanceMatrixResponse,
    locations: LocationType[],
  ): LocationType => {
    const distances = googleMapsDistanceMatrixResponse.rows[0].elements.map(
      (element) => element.distance.value,
    );

    // Create an array of locations with their distances
    const sortedLocations = locations
      .map((location, index) => ({
        location,
        distance: distances[index],
      }))
      .sort((a, b) => a.distance - b.distance);

    let closestLocation = sortedLocations[0].location; // Default to the physically closest location

    // Check each location for open status
    for (const { location } of sortedLocations) {
      const isOpen = isLocationOpen(location);
      if (isOpen) {
        return location;
      }
    }

    return closestLocation; // Return the closest location if no open locations are found
  },
  fetchLocationBusinessHours: async (locationId: string) => {
    const { data, loading, error } = await fetchLocationHours(locationId);
    if (error) {
      console.log("fetchLocationBusinessHours -> error", error);
      return;
    }
    return data;
  },
};

const LocationsContextProvider = ({ children }) => {
  const [locations, setLocations] = useSessionStorage("locations", []);
  const [selectedLocation, setSelectedLocation] = useSessionStorage("selectedLocation", {});

  const [isOpenExternalDeliveryModal, setIsOpenExternalDeliveryModal] = useState(false);
  const [isOpenMultiLocationModal, setIsOpenMultiLocationModal] = useState(false);

  const socket = React.useContext(SocketContext);

  // create a function that will update the selected location business hours
  const setSelectedLocationBusinessHours = ({
    businessHours,
    closedHours,
    delivery,
    pickup,
  }) => {
    if (_.isEmpty(pickup.days)) {
      pickup = Object.assign({}, pickup, { days: [...businessHours.days] });
    }

    if (_.isEmpty(delivery.days)) {
      delivery = Object.assign({}, delivery, { days: [...businessHours.days] });
    }

    // update the selected location business hours
    setSelectedLocation(
      Object.assign({}, selectedLocation, { businessHours, closedHours, delivery, pickup }),
    );
  };

  useEffect(() => {
    if (!socket || !selectedLocation.id) return;
    const eventName = `${process.env.GATSBY_STRAPI_RESTAURANT_ID}-${selectedLocation.id}-locationsContextUpdate`;
    socket.on(eventName, (updatedLocation) => {
      setSelectedLocation(Object.assign({}, selectedLocation, updatedLocation));
    });
  }, [socket, selectedLocation?.id]);

  return (
    <LocationsContext.Provider
      value={{
        locations,
        setLocations,
        selectedLocation,
        setSelectedLocation,
        isOpenExternalDeliveryModal,
        setIsOpenExternalDeliveryModal,
        isOpenMultiLocationModal,
        setIsOpenMultiLocationModal,
        setSelectedLocationBusinessHours,
        ...locationFunctions,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
export default LocationsContext;
export { LocationsContextProvider, LocationsContext };
