import { gql, useMutation } from "@apollo/client";

const useResetPassword = () => {
  const MUTATE = gql`
    mutation ResetPassword(
      $password: String!
      $passwordConfirmation: String!
      $code: String!
    ) {
      resetPassword(
        code: $code
        password: $password
        passwordConfirmation: $passwordConfirmation
      ) {
        jwt
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useResetPassword;
