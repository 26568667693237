import { gql, useMutation } from "@apollo/client";
import Fragments from "../fragments";

const useCreateCart = () => {
  const MUTATE = gql`
    mutation CreateCart(
      $location: ID!
      $restaurant: ID!
      $user: ID
      $lineitems: [ComponentOrderLineitemInput]
      $email: String
      $phone: String
      $orderType: ENUM_CARTS_ORDERTYPE
      $state: ENUM_CARTS_STATE
      $note: String
      $tip: Float
      $scheduleTime: ComponentOrderScheduleTimeInput
      $shippingAddress: ComponentLocationAddressInput
    ) {
      createCart(
        input: {
          data: {
            restaurant: $restaurant
            location: $location
            user: $user
            lineitems: $lineitems
            email: $email
            phone: $phone
            orderType: $orderType
            state: $state
            note: $note
            tip: $tip
            scheduleTime: $scheduleTime
            shippingAddress: $shippingAddress
          }
        }
      ) {
        cart {
          ...CartFragment
        }
      }
    }
    ${Fragments.cart}
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useCreateCart;
