import React, { useState } from "react";

const ModalContext = React.createContext({});

const ModalContextProvider = ({ children }) => {
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        showDeliveryModal,
        setShowDeliveryModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
export default ModalContext;
export { ModalContextProvider, ModalContext };
