import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import Fragments from "../fragments";

const useUserMe = () => {
  const QUERY = gql`
    query GetMe($restaurantId: ID!) {
      me {
        id
        email
        confirmed
        orders(
          sort: "created_at:desc"
          limit: 10
          start: 0
          where: { restaurant: { id: $restaurantId } }
        ) {
          ...OrderFragment
        }

        displayName
        firstName
        lastName
        phone
        addresses {
          ...AddressFragment
        }
      }
    }
    ${Fragments.order}
    ${Fragments.address}
  `;
  const [executeQuery, { data, loading, error }] = useLazyQuery(QUERY);

  const onExecuteQuery = () => {
    executeQuery({
      variables: { restaurantId: process.env.GATSBY_STRAPI_RESTAURANT_ID },
    });
  };
  return [data, loading, error, onExecuteQuery];
};

export default useUserMe;
