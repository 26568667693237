import { gql } from "@apollo/client";
import address from "./address";
import offer from "./offer";

const cart = gql`
  fragment CartFragment on Carts {
    id
    token
    total
    taxTotal
    subtotal
    tip
    discountTotal
    deliveryFees
    email
    phone
    canDeliver
    scheduleTime {
      display
      to
      from
    }
    orderType
    lineitems {
      id
      title
      item {
        id
        price
        photo {
          url
          alternativeText
        }
        name
        localeShareId
      }
      choices {
        title
        item {
          id
          name
          price
        }
        modifier {
          id
          name
        }
        unitPrice
        quantity
      }
      unitPrice
      totalPrice
      note
      quantity
    }
    offer {
      ...OfferFragment
    }
    billingAddress {
      ...AddressFragment
    }
    shippingAddress {
      ...AddressFragment
    }
  }
  ${address}
  ${offer}
`;

export default cart;
