import { useContext, useMemo } from "react";
import { LocationsContext } from "../context";
import _ from "lodash";
import { postalCodePatternByCountry } from "../utils/postalCodeChecker";

const usePostalCodePattern = ({ country }) => {
  const { selectedLocation } = useContext(LocationsContext);

  const getPattern = useMemo(() => {
    if (_.isEmpty(country) && _.isEmpty(selectedLocation?.address?.country)) return;
    const key = country
      ? _.lowerCase(country)
      : _.lowerCase(selectedLocation.address?.country);
    if (_.isEmpty(selectedLocation.address?.countryCode)) return;
    return postalCodePatternByCountry[key];
  }, [selectedLocation, country]);

  return getPattern;
};

export default usePostalCodePattern;
