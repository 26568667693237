import { gql, useMutation } from "@apollo/client";

const useUpdateMe = () => {
  const MUTATE = gql`
    mutation useUpdateMe(
      $email: String
      $password: String
      $firstName: String
      $lastName: String
      $phone: String
      $addresses: [editComponentLocationAddressInput]
    ) {
      updateMe(
        input: {
          email: $email
          password: $password
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          addresses: $addresses
        }
      ) {
        id
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useUpdateMe;
