import { gql, useMutation } from "@apollo/client";

const useRegister = () => {
  const MUTATE = gql`
    mutation Register(
      $username: String!
      $email: String!
      $password: String!
      $createdBy: String
    ) {
      register(
        input: {
          username: $username
          email: $email
          password: $password
          createdBy: $createdBy
        }
      ) {
        jwt
        user {
          email
          confirmed
        }
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useRegister;
