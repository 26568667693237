import React from "react";
import { useLocalStorage } from "../hooks";

const RestaurantContext = React.createContext({});

const RestaurantContextProvider = ({ children }) => {
  const [restaurant, setRestaurant] = useLocalStorage("restaurant", {});

  return (
    <RestaurantContext.Provider
      value={{
        restaurant,
        setRestaurant,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};
export default RestaurantContext;
export { RestaurantContextProvider, RestaurantContext };
