const useAddressFormat = () => {
  const formatField = (field) => {
    return `${field ? field + ", " : ""}`;
  };

  return (address, type) => {
    return `${formatField(address.address_1)}${formatField(address.city)} ${formatField(
      address.province,
    )}${formatField(address.postalCode)}${formatField(address.country)}`.slice(0, -2);
  };
};

export default useAddressFormat;
