// create a context which will hold the socket.io instance

import React, { createContext, useState, useEffect } from "react";
import io from "socket.io-client";

const SocketContext = createContext<any>({} as any);

const SocketContextProvider = (props) => {
  const [socketClient, setSocketClient] = useState<any>(null);

  useEffect(() => {
    const socketClient = io(process.env.GATSBY_STRAPI_URL || "http://localhost:1337");
    setSocketClient(socketClient);

    return () => {
      socketClient.close();
    };
  }, []);

  return (
    <SocketContext.Provider value={socketClient}>{props.children}</SocketContext.Provider>
  );
};

export default SocketContext;
export { SocketContextProvider, SocketContext };
