import { gql, useMutation } from "@apollo/client";
import Fragments from "../fragments";

const useRemoveOffer = () => {
  const MUTATE = gql`
    mutation RemoveOffer($cartToken: String!) {
      removeOffer(cartToken: $cartToken) {
        cart {
          ...CartFragment
        }
      }
    }
    ${Fragments.cart}
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return { data, loading, error, setData };
};

export default useRemoveOffer;
