import { useCallback, useContext } from "react";
import { CartContext } from "../context";
import _ from "lodash";
import produce from "immer";

// Define a custom hook to update the quantity of items in a cart
// it relies on the CartContext to access and update the cart data
// it returns a memoized function that can be called with an object
// containing the id of the item and the new quantity to update the cart data
const useUpdateCartQty = () => {
  const cartContext = useContext(CartContext);

  // useCallback hook to memoize the function that updates the cart line items and calculates the cart totals
  const getQty = useCallback(
    ({ id, quantity }) => {
      // produce is a function from the immer library that allows us to cerate a new updated version of the cart line items
      // if the quantity is less than 1, we delete the item from the cart
      // else we update the quantity of the item with the provided quantity
      const updatedCart = produce(cartContext.cartLineItems, (draft) => {
        if (quantity < 1) delete draft[id];
        else draft[id].quantity = quantity;
      });

      // the updated cart line items are set in the cart context using the setCartLineItems function
      cartContext.setCartLineItems(updatedCart);

      // calculate the new cart totals
      // by iterating over the updated cart line items
      // and updating the qty and subtotal, and the quantitiesPerItem object of the cart totals object
      let cartTotals = { qty: 0, subtotal: 0, quantitiesPerItem: {} };
      _.forEach(updatedCart, (v, k) => {
        cartTotals.qty += v.quantity;
        cartTotals.subtotal += v.unitPrice * v.quantity;

        if (!cartTotals.quantitiesPerItem[v.item.id])
          cartTotals.quantitiesPerItem[v.item.id] = 0;
        cartTotals.quantitiesPerItem[v.item.id] += v.quantity;
      });
      // finally, the updated cart totals are set in the cart context using the setCartTotals function
      cartContext.setCartTotals((c) => {
        return {
          ...c,
          ...cartTotals,
        };
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartContext.cartLineItems],
  );

  // return the memoized function
  // which can be called with an object containing the id of the item and the new quantity to update the cart data
  // this fn is made to be efficient by using the useCallback hook
  // which ensures that the function is not re-created on every render but only when the dependencies change
  return getQty;
};

export default useUpdateCartQty;
