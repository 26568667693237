import React from "react";
import Icofont from "../IcoFont";

import { Button } from "react-bootstrap";

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <>
      {options.type === "info" && (
        <div style={{ ...style }}>
          <Icofont icon="info" /> <span className="px-3">{message}</span>
          <Button variant="link" onClick={close}>
            <Icofont icon="close-line" size={2} />
          </Button>
        </div>
      )}

      {options.type === "success" && (
        <div style={{ ...style }}>
          <Icofont icon="check" /> <span className="px-3">{message}</span>
          <Button variant="link" onClick={close}>
            <Icofont icon="close-line" size={2} />
          </Button>
        </div>
      )}

      {options.type === "error" && (
        <div style={{ ...style }} className="alert alert-danger" role="alert">
          <Icofont icon="exclamation" />
          <span className="px-3">{message}</span>
          <Button variant="link" onClick={close}>
            <Icofont icon="close-line" size={2} />
          </Button>
        </div>
      )}
    </>
  );
};

export default AlertTemplate;
