import React, { useState, useContext, useEffect } from "react";
import { useUserMe } from "../queries";
import { UserAuthContext } from "./index";
import _ from "lodash";
import { useAlert } from "react-alert";
import { useNavigate } from "../hooks";

const CustomerContext = React.createContext({});

const CustomerContextProvider = ({ children }) => {
  const [customer, setCustomer] = useState({});
  const navigate = useNavigate();

  const { isAuthenticated, removeUser } = useContext(UserAuthContext);
  const [userMe, loading, error, executeUserMe] = useUserMe();

  const alert = useAlert();
  useEffect(() => {
    if (!isAuthenticated) return;
    executeUserMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (_.isEmpty(userMe)) return;
    if (_.isEmpty(userMe.me)) return;

    setCustomer({
      ...userMe.me,
    });
  }, [userMe, loading, error]);

  useEffect(() => {
    if (_.isEmpty(error)) return;
    if (_.isEmpty(alert)) return;
    console.log(JSON.stringify(error));
    if (_.includes(error.message, "email is not confirme")) {
      alert.error("You Need to Confirm Your Email");
      removeUser();
      navigate("/email-confirmation");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, alert]);

  return (
    <CustomerContext.Provider
      value={{
        customer,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
export default CustomerContext;
export { CustomerContextProvider, CustomerContext };
