// Path: src/providers/index.js
// Description: This is the component that will wrap the entire app and will be used to query for data on runtime
import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./src/styles/App.css";
import "./src/styles/icofont/icofont.css";
import "animate.css/animate.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./src/styles/phoneInput.css";

import Providers from "./src/providers";

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};
